
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { fixPrice, isValidNumber, paymentIcon, stringifySafe, updateNestedObject } from '@/utils/general'
  import { Payment, PaymentOrder } from '@/entities/finance'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import logOut from '@/components/LogOut.vue'
  import { ItemType, SaleOrder, SaleOrderItem } from '@/entities/sales'
  import { SaleOrderView } from '@/components/forms/view/SaleOrderView'
  import GCostField from '@/components/core/input/GCostField.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { Person } from '@/entities/persons'
  import { Debounce } from '@/utils/decorators'
  import ValidationPaymentComponent from '@/components/forms/validationSaleOrder/validationPaymentComponent.vue'
  import TrafficTicketComponent from '@/components/forms/trafficTicket/TrafficTicketComponent.vue'
  import ProgressCell from '@/components/dataTables/cell/ProgressCell.vue'

@Component({
  components: {
    ProgressCell,
    TrafficTicketComponent,
    ValidationPaymentComponent,
    GRadioButton,
    Agenda,
    GDatePicker,
    GCostField,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
  methods: { paymentIcon, isValidNumber },
})
  export default class ValidateSaleOrderForm extends SaleOrderView {
  transferCost = 0
  openPayment = false
  paymentSelected = null
  payments: Array<Payment> = []
  lead = plainToInstance(Lead, {})
  saleOrder = plainToInstance(SaleOrder, {})
  process: Process = plainToInstance(Process, {})
  paymentOrder: PaymentOrder = plainToInstance(PaymentOrder, {})
  minValue = null
  maxDays = null
  newAgenda = null
  activity = null
  activities = []

  purchase = {
    open: false,
    title: '',
    item: null,
  }

  products = []
  paymentPending = ''
  errorSignerMessages = ''
  errorAcquirerMessages = ''
  errorMessages = ''
  saleMetadata = null
  title = ''
  idProcess = null
  showDetail = false
  fileInfo = null
  panels = [0, 1]
  section = {
    interviews: false,
    documents: false,
    payment: false,
  }

  declare $refs: {
    form: HTMLFormElement
    fieldSigners: LinkedPerson
    fieldClient: LinkedPerson
    fieldAcquirer: LinkedPerson
  };

  warrantyAmount = null
  metadata = {}
  radioButtonMessage = {
    isWarranty: '',
    isWarrantyExtra: '',
    isTag: '',
  }

  formData = {
    commentWarranty: '',
    commentExtraWarranty: '',
    commentTag: '',
    client: null,
    discount: null,
    order: null,
    isWarranty: null,
    isWarrantyExtra: null,
    isTag: null,
    insurance: null,
    broker: null,
    deductible: null,
    cost: null,
    warranty: null,
    signers: null,
    acquirer: null,
    documentType: null,
    linkContract: null,
    fileWarranty: [],
    comment: null,
    fileExtraWarranty: [],
    fileTag: [],
    fileCav: [],
    filePenalties: [],
    fileLicense: [],
    contract: [],
    discountPostFinancing: null,
  }

  fields = {
    warranties: {
      properties: {
        label: 'Garantia',
        required: true,
        itemText: 'warrantyDescription',
        itemValue: 'id',
        rules: [],
      },
      items: [],
      rules: [],
    },
    deductible: {
      properties: {
        label: 'Deducible',
        required: true,
        itemText: 'description',
        itemValue: 'id',
        rules: [],
      },
      items: [],
      rules: [],
    },
    insurance: {
      properties: {
        label: 'Corredora de seguros',
        required: true,
        rules: [],
        itemText: 'formInfo',
        itemValue: 'id',
        returnObject: true,
      },
      items: [],
      rules: [],
    },
    brokers: {
      properties: {
        label: 'Compañia de seguros',
        required: true,
        rules: [],
        itemText: 'formInfo',
        itemValue: 'id',
        returnObject: true,
      },
      items: [],
      rules: [],
    },
    client: {
      properties: {
        required: true,
        disabled: true,
        properties: {
          label: 'Cliente',
          rules: [],
          itemText: 'formInfo',
          itemValue: 'id',
          returnObject: true,
        },
      },
      items: [],
      rules: [],
    },
    acquirer: {
      properties: {},
      items: [],
      rules: [],
    },
    signers: {
      properties: {},
      items: [],
      rules: [],
    },
    documentType: {
      properties: {},
      rules: null,
      items: [],
    },
    linkContract: {
      properties: {},
    },
    order: {
      properties: {
        label: 'Abonos',
        required: true,
        readOnly: true,
        rules: [],
      },
    },
  }

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()

    const { id, uid, model, title, saleMetadata } = this

    if ((!isNaN(uid) && (model === 'SaleOrder' || model === '')) || !isNaN(id)) {
      const saleId = id || uid
      await this.getSellInfo(saleId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(saleMetadata, title, Boolean(this.saleOrder?.id))
    }
    this.saleOrder.deal.lead = await this.fetchData({
      query: { name: 'fetchLite', model: 'Lead', params: { id: this.saleOrder?.deal?.lead?.id } },
    })
    this.setTheBackup()

    this.setDetails()
    this.loadingForm = false
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return

    if ('paymentForm' in backup && backup.paymentForm) {
      const { paymentForm: { paymentType, payer, amount, bank, reference, paymentDate, comment, paymentBackup } } = backup

      const pay = plainToInstance(Payment, {})
      pay.type = paymentType
      pay.payer = payer
      pay.reference = reference
      pay.amount = amount
      pay.issuingBank = bank
      pay.date = paymentDate
      pay.comment = comment
      pay.paymentBackup = paymentBackup
      this.payments.push(pay)

      if (!this.formData.order?.length) {
        this.formData.order = this.payments
      } else {
        this.formData.order.push(pay)
      }
      this.backup.paymentForm = null
    }

    this.setPersons(backup)
  }

  setPersons (backup) {
    if ('acquirer' in backup && backup.acquirer) {
      const backupAcquirer = backup.saleForm?.acquirer || []
      const acquirers = [backup.acquirer, ...backupAcquirer?.filter(acquirer => acquirer.id !== backup.acquirer.id)].filter(Boolean)
      this.formData.acquirer = acquirers
      this.fields.acquirer.items = acquirers
    }

    if ('signers' in backup && backup.signers) {
      const backupSigners = backup.saleForm?.signers || []
      const signers = [backup.signers, ...backupSigners?.filter(signer => signer.id !== backup.signers.id)].filter(Boolean)
      this.formData.signers = signers
      this.fields.signers.items = signers
    }
  }

  cancelSale () {
    const { formData: { fileCav, fileLicense, filePenalties, fileExtraWarranty, fileWarranty, fileTag, contract } } = this
    this.setBackup(null)
    const files = [...fileCav, ...fileLicense, ...filePenalties, ...fileExtraWarranty, ...fileWarranty, ...fileTag, ...contract]

    this.close(files)
  }

  async getSellInfo (id) {
    this.saleOrder = await this.fetchData({
      query: { name: 'fetch', model: 'SaleOrder', params: { id } },
      force: true,
    })

    if (this.saleOrder.discount?.value) {
      this.formData.discount = this.saleOrder?.discount?.value
    }

    if (this.saleOrder.discountPostFinancing?.value) {
      this.formData.discountPostFinancing = this.saleOrder?.discountPostFinancing?.value
    }

    this.setLeadInfo(this.saleOrder.deal.lead)

    this.formData.order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_deal: { _eq: this.saleOrder.deal.id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    this.formData.acquirer = this.saleOrder.deal.stock.interveners
    this.fields.acquirer.items = this.saleOrder.deal.stock.interveners
    await this.getAdditionalInfo()
    this.transferCost = await this.calculateTransfer(this.saleOrder?.deal?.stock?.id)
  }

  async getAdditionalInfo () {
    this.fields.insurance.items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { company_type: { name: { _eq: 'insurance_carrier' } } },
    })
    const brokers = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { company_type: { name: { _eq: 'broker' } } },
    })

    this.fields.brokers.items = brokers
    const deductibles = await this.fetchData({
      query: { name: 'find', model: 'DeductibleType' },
    })
    this.fields.deductible.items = deductibles
    const products = await this.fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: this.saleOrder.id } },
      force: true,
    })

    const insurance = products?.find(product => product.name === 'insurance')
    this.formData.insurance = this.fields.insurance.items?.find(insuranc => insuranc.id === insurance?.company?.id)
    this.formData.broker = brokers?.find(broker => broker.id === insurance?.broker?.id)
    this.formData.cost = insurance?.cost
    this.formData.deductible = deductibles?.find(deductible => deductible.id === insurance?.deductible?.id)?.id

    const tag = products?.find(product => product.name === 'tag')
    const warranty = products?.find(product => product.name === 'warranty')
    this.warrantyAmount = warranty?.cost
    this.products = products || []
    await this.setFiles(tag, products?.find(product => product.name === 'warranty'))

    this.fields.documentType.items = await this.fetchData({
      query: { name: 'find', model: 'SupportDocumentType' },
    })
  }

  async setFiles (tag, warranty) {
    const { filesProcess: { saleProduct } } = this

    this.fields.warranties.items = await this.fetchData({
      query: { name: 'find', model: 'Offer' },
      filter: { variant: { agreement_product: { product: { name: { _eq: 'warranty' } } } } },
    })

    this.formData.warranty = this.fields.warranties.items.find(item => item.id === warranty?.offer?.id)?.id

    this.formData.comment = warranty?.comment
    if (warranty?.id) {
      const warrantyFiles = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: warranty.id } },
            { parameter: { process: { id: { _eq: saleProduct } } } },
          ],
        },
        force: true,
      })
      const fileWarranty = warrantyFiles.filter(file => file.parameter.name === 'warranty') || []
      const fileExtraWarranty = warrantyFiles.filter(file => file.parameter.name === 'extra_warranty') || []
      this.formData.fileWarranty = fileWarranty
      this.formData.fileExtraWarranty = fileExtraWarranty

      this.formData.isWarranty = fileWarranty[0].valid
      this.formData.isWarrantyExtra = fileExtraWarranty[0].valid
    }

    if (tag?.id) {
      const tagFiles = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: tag.id } },
            { parameter: { process: { id: { _eq: saleProduct } } } },
          ],
        },
        force: true,
      })
      const tagFile = tagFiles.filter(file => file.parameter.name === 'tag') || []
      this.formData.fileTag = tagFile
      this.formData.isTag = tagFile?.[0]?.valid
    }
  }

  saveBackup (item = null) {
    const { backup, formData, panels, saleOrder: { deal: { lead }, deal } } = this
    if (backup) {
      backup.lead = lead
      backup.saleForm = { ...formData, panels }
      backup.editPayment = item
      this.setBackup(backup)
    } else {
      this.setBackup({
        saleForm: { ...formData, panels },
        editPayment: item,
        lead,
        deal,
        client: null,
        signers: null,
        acquirer: null,
      })
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('SaleOrder', 'validator_sale_order')
    const { metadata: metadataReserve } = this.getForm('Reserve', 'validator_reserve')
    const { fields: { config: { init: { value } } } } = metadataReserve as Form
    this.minValue = value.minValue
    this.maxDays = value.maxDays
    const { form, fields: saleFields } = metadata as Form
    this.fields.acquirer.properties = saleFields.acquirer.properties
    this.fields.signers.properties = saleFields.signers.properties
    this.fields.documentType.properties = saleFields.documentType.properties

    this.fields.linkContract.properties = saleFields.linkContract.properties
    this.saleMetadata = metadata
    this.title = form.title

    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    const { id } = process[0] as Record<string, any>

    this.idProcess = id
  }

  setDetails () {
    const {
      saleMetadata,
      saleOrder,
      saleOrder: { deal: { lead } },
      formData: { discount },
      transferCost,
      totalAmount,
      warrantyAmount,
    } = this

    const deal = lead.deals?.find(deal => deal.isSale)
    if (!deal) return

    const pos = deal.saleOrders[0].saleOrderItems.findIndex(item => item.type.name === 'discount')

    if (!pos || pos < 1) {
      deal.saleOrders[0].saleOrderItems.push(plainToInstance(SaleOrderItem, {
        amount: discount,
        type: plainToInstance(ItemType, { id: 2, name: 'discount' }),
      }))
    } else {
      deal.saleOrders[0].saleOrderItems[pos].amount = discount
    }
    deal.saleOrders[0].status = saleOrder.status
    deal.lead = lead
    deal.lead.sale.stock.transferCost = transferCost
    deal.lead.sale.totalAmount = totalAmount
    deal.lead.sale.warrantyAmount = fixPrice(warrantyAmount)
    deal.warrantyAmount = fixPrice(warrantyAmount)
    deal.totalAmount = totalAmount

    this.metadata = {
      data: deal,
      metadata: saleMetadata,
    }

    this.showDetail = (Boolean(saleOrder?.id))
  }

  async preSend () {
    // TODO: add 2 when contract
    this.panels = [0, 1]
    await this.send()
  }

  async handleSaleOrderWithPaymentRejected (orders) {
    const { saleOrderIsDisabled, saleOrder, saleStatus } = this

    const isPaymentToUpdate = orders.some(order => order.status.isToUpdating)

    if (isPaymentToUpdate) {
      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: saleOrder?.id,
          id_process_status: saleStatus.toUpdate[0].id,
        },
      })
    }

    if (saleOrderIsDisabled) {
      await this.close()
      return true
    }

    return false
  }

  validateFields () {
    const { formData: { fileTag, isTag } } = this
    const keys = ['isWarranty', 'isWarrantyExtra']

    let flag = false
    keys.forEach(key => {
      if (this.formData[key] === null) {
        this.radioButtonMessage[key] = 'Campo requerido'
        flag = true
      }
    })

    if (fileTag.length && isTag === null) {
      this.radioButtonMessage.isTag = 'Campo requerido'
      flag = true
    }
    return flag
  }

  @Debounce(500)
  async send () {
    const {
      formData: { isWarranty, isWarrantyExtra, isTag, fileTag },
      paymentPending,
      saleOrder,
      totalPercentageValidate,
      isValid,
    } = this

    if (isValid) {
      await this.close()
      return
    }
    this.loadingForm = true
    const orders = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_deal: { _eq: saleOrder.deal.id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    if (await this.handleSaleOrderWithPaymentRejected(orders) || this.validateFields()) {
      return
    }

    if (!this.$refs.form.validate() || paymentPending?.length) {
      this.loadingForm = false
      return
    }

    const {
      saleStatus,
    } = this

    const isPaymentPending = orders.filter(order => order.type !== 'financing').some(order => !order.status?.isClosed && order.closingReason?.successful)

    const isToUpdate = !isWarranty || !isWarrantyExtra || (!isTag && fileTag.length)

    if ((isPaymentPending || totalPercentageValidate !== '100.00') && !isToUpdate) {
      await this.close()
      return
    }

    this.loadingForm = true

    if (isToUpdate) {
      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: saleOrder?.id,
          id_process_status: saleStatus.toUpdate[0].id,
          id_validator: this.idEmployee,
        },
      })
    } else {
      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: saleOrder?.id,
          id_process_status: saleStatus.toContract[0].id,
          id_validator: this.idEmployee,
        },
      })
    }

    await this.handleFiles()
    this.loadingForm = false

    await this.close()
  }

  async handleFiles () {
    const {
      formData: {
        fileWarranty,
        fileExtraWarranty,
        fileTag,
        commentWarranty,
        commentExtraWarranty,
        commentTag,
        isWarranty,
        isWarrantyExtra,
        isTag,
      },
    } = this
    if (fileWarranty.length) {
      await this.insertFileProcessInfo({
        id: fileWarranty[0].id,
        comment: commentWarranty,
        valid: isWarranty,
        validation_type: 'manual',
      })
    }
    if (fileExtraWarranty.length) {
      await this.insertFileProcessInfo({
        id: fileExtraWarranty[0].id,
        comment: commentExtraWarranty,
        valid: isWarrantyExtra,
        validation_type: 'manual',
      })
    }
    if (fileTag.length) {
      await this.insertFileProcessInfo({
        id: fileTag[0].id,
        comment: commentTag,
        valid: isTag,
        validation_type: 'manual',
      })
    }
  }

  get change () {
    const { formData, panels, section } = this

    return stringifySafe([formData, panels, section])
  }

  async searchPerson ({ input, type }) {
    if (!input?.length || input?.length < 2) return null

    const { saleMetadata } = this

    if (!saleMetadata) return null
    const { fields } = saleMetadata as Form

    if (!fields?.client) return
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', input, ['name_person_type'])

    const persons = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { ...query },
      force: true,
    })

    if (type === 'acquirer') {
      this.fields.acquirer.items = persons
    } else {
      this.fields.signers.items = persons
    }
  }

  async editPerson (person) {
    const { backup, formData } = this

    if (!backup) {
      this.setBackup({
        saleForm: formData,
      })
    } else {
      backup.saleForm = formData
      this.setBackup(backup)
    }

    await this.$router.push({ name: 'generic-person-nested', params: { model: 'SaleOrder', uid: null, id: person.id } })
  }

  async goPerson (type) {
    const { backup, formData } = this

    if (!backup) {
      this.setBackup({ saleForm: formData })
    } else {
      backup.saleForm = formData
      this.setBackup(backup)
    }

    const idPerson = type !== 'signer' ? this.formData[type]?.id : null
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'SaleOrder', uid: null, id: idPerson } })
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get discountIsPending () {
    const { saleOrder } = this

    if (!saleOrder) return false

    const { deal } = saleOrder

    if (!deal) return false

    const { lead: { sale } } = deal

    if (!sale) return false
    const { financial } = sale
    if (!financial) return false
    const { evaluation } = financial

    return Boolean(evaluation)
  }

  get discount () {
    const { lead: { sale } } = this

    if (!sale) return 0
    const { stock } = sale

    return stock?.price?.bonus?.amount || stock?.price?.margin || 0
  }

  get paymentError () {
    const { minValue, formData: { order } } = this
    if (!order) return ''
    return this.paymentValid(order) ? '' : `La suma de los pagos debe ser mayor ${fixPrice(minValue)}`
  }

  paymentValid (payments) {
    const { minValue } = this
    const suma: number = payments.reduce((total, item) => {
      return total + Number(item.amount)
    }, 0)

    return suma >= minValue
  }

  setLeadInfo (lead) {
    const { client } = lead as Lead

    this.formData.client = client
    this.fields.client.items = [client]
  }

  async goToOrder () {
    const { saleOrder } = this
    if (this.backup?.paymentForm) {
      this.backup.paymentForm = null
      this.backup.client = null
    }
    this.saveBackup()

    const idDeal = saleOrder?.deal?.id ? saleOrder?.deal?.id.toString() : 'create'
    await this.$router.push({
      name: 'generic-payment',
      params: { parentModel: 'Deal', parentId: idDeal, id: 'create' },
    })
  }

  get bindInsurance () {
    const { fields: { insurance } } = this

    if (!insurance) return {}

    return { ...insurance.properties, items: insurance.items }
  }

  get bindBrokers () {
    const { fields: { brokers } } = this

    if (!brokers) return {}

    return { ...brokers.properties, items: brokers.items }
  }

  get bindDeductible () {
    const { fields: { deductible } } = this

    if (!deductible) return {}

    return { ...deductible.properties, items: deductible.items }
  }

  get bindWarranty () {
    const { fields: { warranties } } = this

    if (!warranties) return {}

    return { ...warranties.properties, items: warranties.items }
  }

  get bindSigners () {
    const { fields: { signers } } = this

    if (!signers) return {}

    return { ...signers.properties, items: signers.items }
  }

  get bindAcquirer () {
    const { fields: { acquirer } } = this

    if (!acquirer) return {}

    return { ...acquirer.properties, items: acquirer.items }
  }

  get disabled () {
    return false
  }

  get isDigitalContract () {
    const { formData: { documentType }, documentFilesParameters } = this
    if (!documentType) return false

    const file = documentFilesParameters.find(file => file.name === `buyer_${documentType.name}`)

    if (file) {
      this.setFileProperties('contract', file)
    } else {
      this.filesProperties.contract = null
    }
    this.setFileProperties('contract', file)
    return documentType?.name === 'digital_contract'
  }

  get discountAuthorized () {
    const { saleOrder } = this

    if (!saleOrder) return 0

    return (Number(saleOrder?.discountPostFinancing?.value || 0) + Number(saleOrder?.discount?.value || 0))
  }

  get priceList () {
    const { saleOrder } = this

    if (!saleOrder) return 0

    return saleOrder?.product?.amount || saleOrder?.deal?.stock?.price?.amount || 0
  }

  get totalPayments () {
    const { formData: { order } } = this

    if (!order?.length) return 0

    return order.reduce((total, item) => total + Number(item.amount), 0)
  }

  get financedAmount () {
    const { saleOrder } = this

    const evaluation = saleOrder?.financings?.[0]?.evaluations.find(evaluation => evaluation.isApproved)

    let financedAmount = 0
    if (evaluation) {
      financedAmount = saleOrder?.financings?.[0].requestAmount
    }

    return financedAmount
  }

  get total () {
    const { totalPaymentsPaid, totalAmount } = this

    return totalAmount - totalPaymentsPaid
  }

  get isSaleInProcess () {
    const { saleOrder } = this

    return Boolean(saleOrder?.status?.isActive) || Boolean(saleOrder?.status?.isToUpdating)
  }

  get totalValid () {
    const { totalPaymentsValidated, totalAmount } = this

    return totalAmount - totalPaymentsValidated
  }

  get totalAmount () {
    const { discountAuthorized: discount, priceList, transferCost, warrantyAmount } = this

    return priceList + (transferCost || 0) + (warrantyAmount || 0) - (discount || 0)
  }

  @Watch('formData.signers', { immediate: true, deep: true })
  onSignersChange (val: Person[]) {
    if (!val?.length) return

    this.validatePersonInfo(val, 'firmante', 'errorSignerMessages')
  }

  async validatePersonInfo (persons, type, field) {
    let error = false
    for (const person of persons) {
      const address = await this.fetchData({
        query: { name: 'find', model: 'PersonAddress' },
        filter: { id_person: { _eq: person.id } },
        force: true,
      })

      if (!person?.uid && !error) {
        error = true
        this[field] = `El ${type} ${person.fullName} no tiene un RUT asociado`
      }

      if (!address?.length && !error) {
        error = true
        this[field] = `El ${type} ${person.fullName} no tiene una dirección asociada`
      }
    }

    if (!error) {
      this[field] = ''
    }
  }

  @Watch('formData.acquirer', { immediate: true, deep: true })
  onAcquirerChange (val: Person[]) {
    if (!val?.length) return

    this.validatePersonInfo(val, 'adquiriente', 'errorAcquirerMessages')
  }

  get watchValidation () {
    const { formData: { isWarranty, isWarrantyExtra, isTag } } = this

    return [isWarranty, isWarrantyExtra, isTag]
  }

  @Watch('watchValidation', { immediate: true, deep: true })
  onValidationChange () {
    const keys = ['isWarranty', 'isWarrantyExtra', 'isTag']

    keys.forEach(key => {
      if (this.formData[key] !== null) {
        this.radioButtonMessage[key] = ''
      }
    })
  }

  openOrder (item) {
    this.paymentSelected = item
    this.openPayment = true
    this.paymentPending = ''
  }

  @Watch('openPayment', { immediate: true })
  async onOpenPaymentChange (val) {
    const { saleOrder } = this
    if (!val && saleOrder?.id) {
      const orders = await this.fetchData({
        query: { name: 'find', model: 'Payment' },
        filter: {
          _and: [
            { id_deal: { _eq: saleOrder.deal.id } },
            { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
          ],
        },
        force: true,
      })

      this.formData.order = orders
      const isPaymentPending = orders.some(order => !(order.status.isClosed && order.closingReason.successful) && !order.status.isToUpdating)

      if (!isPaymentPending) {
        this.paymentPending = ''
      }
    }
  }

  get saleOrderToContract () {
    const { saleOrder } = this
    return saleOrder?.status?.isToContract
  }

  get saleOrderIsDisabled () {
    const { saleOrder } = this

    return saleOrder?.status?.isPending || saleOrder?.status?.isToUpdating
  }

  get totalPercentagePaid () {
    const { totalAmount, totalPaymentsPaid } = this

    return ((totalPaymentsPaid * 100) / totalAmount).toFixed(2)
  }

  get totalPercentageValidate () {
    const { totalPaymentsPaid, totalPaymentsValidated } = this

    return ((totalPaymentsValidated * 100) / totalPaymentsPaid).toFixed(2)
  }

  get totalPaymentsPaid () {
    const { formData: { order } } = this

    if (!order?.length) return 0

    return order.reduce((total, val) => total + Number(val.amount), 0)
  }

  get totalPaymentsValidated () {
    const { formData: { order } } = this

    if (!order?.length) return 0

    return order.filter(item => (item.status.isClosed && item.closingReason.successful) || item?.status?.isPendingPayment).reduce((total, item) => total + Number(item.amount), 0)
  }

  get isToConfirm () {
    const { saleOrder } = this
    return saleOrder?.status?.isToConfirm
  }

  get isContractValid () {
    const { saleOrder } = this

    return saleOrder?.status?.isLegalReview
  }

  get isValid () {
    const { saleOrder } = this

    return saleOrder?.status?.isApproved
  }

  @Watch('isContractValid', { immediate: true })
  handleContract (val) {
    if (!val) return

    this.panels = [2]
  }

  async openContract () {
    const { saleOrder } = this

    await this.$router.push({
      name: 'generic-document-transfer',
      params: {
        uid: saleOrder.document.id.toString(),
      },
    }).catch(() => {
    })
  }
  }
